import React from 'react'
import img from '../../images/limfodrenaz-2.webp'
import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import {Fade} from 'react-reveal'
import {Seo} from '../../components/Seo'

const Limfodrenaz = () => {
  return (
    <>
      <Layout>
        <Seo
          title='Limfodrenaż | Modelowanie Sylwetki'
          description='Limfodrenaż jest to masaż ciała poprzez powietrze umieszczone w
          specjalnych komorach. Podczas zabiegu działamy na krążenie krwi i
          limfy, usuwając zastoje i opuchlizny. Limfodrenaż jest też jedną z
          najważniejszych i najbardziej skutecznych form zwalczania cellulitu.'
          url='https://modelowaniesylwetki-wawer.pl/zabiegi-na-cialo/limfodrenaz'
        />

        <div className='page-container'>
          <div className='page'>
            <div className='content-1'>
              <h1>Limfodrenaż</h1>
              <div className='section'>
                <div className='image-container'>
                  <img
                    src={img}
                    width='660px'
                    height='439px'
                    alt='Limfodrenaż zdjęcie'
                  ></img>
                </div>
                <div className='text'>
                  <p>
                    Limfodrenaż jest to masaż ciała poprzez powietrze umieszczone w
                    specjalnych komorach. Podczas zabiegu działamy na krążenie krwi i
                    limfy, usuwając zastoje i opuchlizny. Limfodrenaż jest też jedną z
                    najważniejszych i najbardziej skutecznych form zwalczania cellulitu.
                    Zabieg jest przyjemny, bezpieczny i wspomaga odchudzanie, likwidację
                    toksyn z organizmu.
                  </p>

                  <p>
                    Zabieg ten polega na oddziaływaniu, w odpowiednich sekwencjach, na
                    skórę i podskórną tkankę łączną. Poprzez stosowanie masażu
                    mechanicznego z równoczesnym wykorzystaniem ciśnienia. Dzięki
                    naciskowi wywieranemu na tkanki umożliwia wykonanie skutecznego
                    drenażu. Pomaga on usunąć rozbitą tkankę tłuszczową i inne szkodliwe
                    produkty przemiany materii. Czas trwania zabiegu wynosi 20-60 minut.
                  </p>
                  <div className='cennik'>
                    <span>CENNIK</span>
                    <h2>LIMFODRENAŻ</h2>
                    <ul>
                      <li>
                        <p>LIMFODRENAŻ - POJEDYNCZY ZABIEG</p>
                        <span className='prices'>30 MIN | 35 zł</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <Fade left>
              <Link to='/kontakt'>
                <button>Umów Wizytę</button>
              </Link>
            </Fade>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Limfodrenaz
